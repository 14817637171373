.header {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-family: "Switzer-Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}
